





































































































































































import { Toast } from "vant";
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import DestinyCartoon from "./DestinyCartoon.vue";
import DestinyFrame from "../DestinyFrame.vue";
import PayPop from "@/views/destiny-draw/components/destinyFate/PayPop.vue";
import DestinyButton from "../destinyButton/DestinyButton.vue";
import { delay, scrollTop, systemPay, wechatPay } from "@/utils/common.util";
import BlindBox from "@/api/blind-box.api";
import { random, cloneDeep, throttle } from "lodash";
import ConfirmOut from "@/components/ConfirmOut.vue";
import SupplyPackageApi, {
  IOrderStatusDetail,
  IOrderV2,
  ISupplyBox,
  ISupplyDetail,
} from "@/api/supply-package.api";
import DestinyReward from "../destinyReward/DestinyReward.vue";
import type { Coupon } from "@/api/mine.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { VanToast } from "vant/types/toast";
import SearchApi from "@/api/bazaar.api";
import WalletPaymentSelector from "@/components/WalletPaymentSelector.vue";
import MemberApi from "@/api/member.api";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    WalletPaymentSelector,
    DestinyReward,
    DestinyButton,
    DestinyCartoon,
    DestinyFrame,
    ConfirmOut,
    PayPop,
  },
})
export default class DestinyFate extends Vue {
  @Ref("destiny-cartoon")
  private readonly destinyCartoonRef: DestinyCartoon | undefined;
  @Ref("WalletPaymentSelector")
  private readonly paymentSelectorRef!: WalletPaymentSelector;

  @Watch("show")
  watchShow(e: any) {}

  @Ref("destiny-reward")
  private readonly destinyRewardRef: DestinyReward | undefined;
  @Prop() member!: boolean;
  @Prop({ default: "margin-top: 42px;" })
  private readonly rootStyle!: string;

  @Prop({ default: () => [] })
  private readonly items!: Array<ISupplyBox>;

  @Prop({ default: () => null })
  private readonly detail!: ISupplyDetail | null;

  @Prop({ default: () => null })
  private readonly coupon!: Coupon | null;
  @Prop()
  private readonly ensure!: any;

  @Prop({ default: 0, required: true })
  private readonly box_id!: number;

  @Prop({ default: false })
  private readonly payBtnDisabled!: boolean;
  show = false;
  styleState = true;
  popState = false;
  radio = "1";
  fakeMoney = 0;
  number = 0;
  price = 0;
  balance_money = 0;
  timertime = 0;

  get soldoutStyle() {
    const { clientWidth } = document.body;
    return {
      height: "48px",
      width: `${clientWidth - 44 - 112}` + "px",
    };
  }

  combine(e: any) {
    this.styleState = e;
  }

  close() {
    this.styleState = true;
    this.show = false;
  }

  globalState = getModule(GlobalStateStore);

  test() {
    this.styleState = true;
  }

  cheep(e: number) {
    this.fakeMoney = e;
  }

  checkRadio(radio: string) {
    this.radio = radio;
  }

  showPop(e: any) {
    this.show = false;
    this.popState = true;
  }

  soldoutHandler() {
    Toast("已售罄");
  }

  money: any = {};
  getBlindStatus = true;

  toDetailSay() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/detailsays`);
    }
    this.$router.push({ name: "detailsays" });
  }

  async created() {
    this.money = await SearchApi.getMoney();
    this.balance_money = this.money.balance_money;
    if (this.member) {
      let checkSuccessBlind: any = await this.checkSuccessBlind();

      if (checkSuccessBlind.status == 2) {
        this.getBlindStatus = true;
      } else {
        this.getBlindStatus = false;
      }
    }
  }

  async checkSuccessBlind() {
    const res = await MemberApi.checkSuccessBlind();
    return res;
  }

  // 动画播放 +
  // __select_choose
  rootClass = "__destiny_fete_frame";
  ConfirmOutPopState = false;

  drawCard(num: 1 | 5 | 10): Promise<void> {
    const { pay_type } = this.detail || {};
    if (pay_type) {
      return this.handleBuyPay(num);
    }
    return this.handleBuyPoint(num);
  }

  get audit_version() {
    return this.globalState.audit_version;
  }

  // 获取抽奖测手气id
  testLuckyDrawId(): number {
    const arr_ids = this.items.map((row) => row.id);
    const random_id = arr_ids[random(0, arr_ids.length - 1)];
    return random_id || 0;
  }

  // 测试手气
  handleTestClick(): void {
    const id = this.testLuckyDrawId();
    this.playAnimation([id], true);
  }

  throttleHandleBuy = throttle(() => {
    const { pay_type } = this.detail || {};
    if (pay_type) {
      return this.handleBuyPay();
    }
    return this.handleBuyPoint();
  }, 1500);

  throttleHandleBuyTimes = throttle(() => {
    const { pay_type } = this.detail || {};
    if (pay_type) {
      return this.handleBuyPay(this.drawTotalCount);
    }
    return this.handleBuyPoint(this.drawTotalCount);
  }, 1500);

  async handleSkip(): Promise<void> {
    this.destinyCartoonRef?.stopAnimation();
  }

  // 积分购买
  async handleBuyPoint(num: 1 | 5 | 10 = 1): Promise<void> {
    const toast = Toast.loading({
      message: "处理中...",
      duration: 0,
      forbidClick: true,
      mask: true,
    });
    const order = await SupplyPackageApi.createOrderV2(
      this.box_id,
      0,
      num
    ).catch((err) => {
      toast.close();
      Toast.fail({ message: err.message, duration: 3000 });
      throw new err();
    });
    toast.close();
    await this.checkOrderAndPlayAnimation(order.order_no);
  }

  @Emit()
  updateCoupon(): void {
    return;
  }

  openTimes = 0;
  couponPricefake = 0;

  async handleBuyPay(num: 1 | 5 | 10 = 1): Promise<void> {
    this.openTimes = num;
    if (this.openTimes != 1) {
      this.couponPricefake = 0;
    } else {
      this.couponPricefake = this.couponPrice;
    }
    // this.show = true;
    // this.styleState = true;
    if (this.openTimes == 1) {
      this.price = this.onePrice;
    } else {
      this.price = this.tenPrice;
    }
    const price = num == 1 ? this.couponPrice || this.onePrice : this.tenPrice;
    this.paymentSelectorRef.show(price, (channel, deduction_wallet) => {
      // console.log(channel, deduction_wallet);
      this.PayOne({ pay_channel: channel, deduction_wallet });
    });
  }

  PayOne(res: any) {
    this.show = false;
    this.styleState = false;
    let price = this.detail?.price || 0;
    if (!price) return;
    if (this.coupon && this.openTimes === 1) price = this.coupon.price * 100;
    return this.handlePayCallback(
      res.pay_channel,
      this.openTimes,
      res.deduction_wallet
    );
  }

  async handlePayCallback(
    channel_number: number,
    num: number,
    deduction_wallet?: boolean
  ): Promise<void> {
    const toast = Toast.loading({
      message: "处理中...",
      duration: 0,
      forbidClick: true,
      mask: true,
    });
    const order = await SupplyPackageApi.createOrderV2(
      this.box_id,
      channel_number,
      num,
      this.globalState.openId || "",
      deduction_wallet
    ).catch((err) => {
      toast.close();
      Toast.fail({ message: err.message, duration: 3000 });
      throw err;
    });

    if (channel_number == 4) {
      await this.checkOrderAndPlayAnimation(order.order_no);
      return;
    }

    toast.message = "支付中...";
    this.updateCoupon();
    this.PayHandler(order, toast, channel_number);
  }

  PayOrder: IOrderV2 | undefined = undefined;
  PayToast: VanToast | undefined = undefined;
  channel_number: number | undefined = undefined;

  async PayHandler(order: IOrderV2, toast: VanToast, channel_number: number) {
    this.PayOrder = order;
    this.PayToast = toast;
    this.channel_number = channel_number;
    order["wechatPay"] = order.weixin;
    toast.clear();
    const result = await systemPay(channel_number, order);
    InteractionLib.updateUserInfo()
    BlindBox.testConpon().then((ret) => !ret && InteractionLib.closeSmall());
    if (channel_number == 0) {
      InteractionLib.updateGoldBlindBoxStatus();
      await this.checkOrderAndPlayAnimation(order.order_no);
      if (InteractionLib.isFlutter()) {
        InteractionLib.goldVipStatusNotice();
      }
      return;
    }
    if (!result && ![1, 2].includes(channel_number)) {
      this.ConfirmOutPopState = true;
      Toast("支付取消");
      return;
    }
    if (!result && [1, 2].includes(channel_number)) return;
    await this.checkOrderAndPlayAnimation(order.order_no);
  }

  confirm() {
    this.PayHandler(this.PayOrder!, this.PayToast!, this.channel_number!);
    this.ConfirmOutPopState = false;
  }

  out() {
    this.ConfirmOutPopState = false;
  }

  async checkOrderAndPlayAnimation(order_no: string): Promise<void> {
    const toast = Toast.loading({
      message: "正在检查支付状态...",
      duration: 0,
      forbidClick: true,
      mask: true,
    });
    const { status, supply_id, detail } = await this.checkOrder(order_no).catch(
      (err) => {
        toast.close();
        Toast.fail({ message: err.message, duration: 3000 });
        throw new err();
      }
    );
    Toast.clear(true);
    this.updateCoupon();
    if (!status) {
      Toast.fail({ message: "购买失败", duration: 3000 });
      return;
    }
    document.documentElement.style.overflow = "hidden";
    return this.playAnimation(supply_id, false, detail as IOrderStatusDetail);
  }

  @Emit()
  updateDetailMini(option: Partial<ISupplyDetail>): Partial<ISupplyDetail> {
    return option;
  }

  upgradeList: any = "";

  async playAnimation(
    supply_id: number[],
    test = true,
    detail?: IOrderStatusDetail
  ): Promise<void> {
    this.getBlindStatus = true;
    Toast.loading({
      message: "抽奖中...",
      forbidClick: true,
      mask: true,
    });
    InteractionLib.updateUserInfo()
    this.rootClass = "__destiny_fete_frame __select_choose"; // 高亮
    const { has_free_receive: has_free_receive_a = false } = this.detail || {};
    const type = supply_id.length === 1 && !test;
    const option: Partial<ISupplyDetail> = {
      has_free_receive: test ? has_free_receive_a : type,
    };
    if (detail) Object.assign(option, detail);
    this.updateDetailMini(option);
    BlindBox.testConpon().then((ret) => !ret && InteractionLib.closeSmall());
    await this.destinyCartoonRef?.playAnimation(
      supply_id.find(Boolean) || 0,
      async () => {
        const items: ISupplyBox[] = [];
        supply_id.forEach((id) =>
          items.push(cloneDeep(this.items.find((row) => row.id === id)!))
        );
        let character = items[0].character;
        this.upgradeList = "";
        if (character != -1) {
          this.upgradeList = await SupplyPackageApi.upgradePreview(character);
        }
        await this.destinyRewardRef?.playAnimation({
          items: items.sort((a, b) => b.level - a.level),
          test,
          freeStatus: this.freeStatus,
          detail,
          recycle_status: this.detail?.recycle_status,
          onePrice: this.onePrice,
          tenPrice: this.tenPrice,
          ensure: this.ensure,
          upgradeList: this.upgradeList,
          box_id: this.box_id,
          couponFlag: true,
          payType: this.payType,
        });
        console.log(this.detail)
        Toast.clear();
        this.rootClass = "__destiny_fete_frame";
        this.destinyCartoonRef?.restore();
        this.updateCoupon();
        if (this.member) {
          let checkSuccessBlind: any = await this.checkSuccessBlind();

          if (checkSuccessBlind.status == 2) {
            this.getBlindStatus = true;
          } else {
            this.getBlindStatus = false;
          }
        }
      }
    );
  }

  async checkOrder(
    order_no: string
  ): Promise<
    | { supply_id: number[]; detail: IOrderStatusDetail; status: true }
    | { supply_id: number[]; detail: Record<string, any>; status: false }
  > {
    for (let i = 0; i < 5; i++) {
      const status = await SupplyPackageApi.orderStatusV2(order_no).catch(
        console.error
      );
      if (status && [2, 3].includes(status.order_status)) {
        return {
          status: true,
          supply_id: status.supply_id,
          detail: status.detail,
        };
      }
      if (status && Number(status.order_status) > 3) {
        throw new Error("购买失败");
      }
      await delay(1000);
    }
    return {
      status: false,
      supply_id: [0],
      detail: {},
    };
  }

  get payType(): boolean {
    const { pay_type = 0 } = this.detail || {};
    return Boolean(pay_type);
  }

  get couponPrice(): number {
    if (!this.coupon) return 0;
    const { price } = this.coupon;
    return price;
  }

  get onePriceClass(): {
    __text_line_through: boolean;
    __text_opacity_55: boolean;
  } {
    const couponPrice = Boolean(this.couponPrice);
    return {
      __text_opacity_55: couponPrice,
      __text_line_through: couponPrice,
    };
  }

  get onePrice(): number {
    const { price = 0, point = 0 } = this.detail || {};
    return this.payType ? price / 100 : point;
  }

  get drawTotalCount(): 5 | 10 {
    const { five_status = false } = this.detail || {};
    return five_status ? 5 : 10;
  }

  get tenPrice(): number {
    const { totle_price = 0, totle_point = 0 } = this.detail || {};
    return this.payType ? totle_price / 100 : totle_point;
  }

  get freeStatus(): boolean {
    if (this.payType) return false;
    const { has_free_receive = false, is_today_free = false } =
      this.detail || {};

    return !has_free_receive && is_today_free;
  }

  handleScroll(): void {
    scrollTop("#destiny-feed");
  }
}
