var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('destiny-frame',{attrs:{"id":"destiny-feed","root-style":"margin: 12px 8px 12px 8px;"}},[_c('template',{slot:"title"},[_vm._v("含有"+_vm._s(_vm.totalKinds)+"款补给")]),_c('template',{slot:"option_btn"},[_c('div',{staticClass:"__flex __mini_text"},[_c('div',{staticClass:"__btn_text"},[_vm._v("单品价值：")]),_c('div',{staticClass:"__btn_text __underline"},[_vm._v(_vm._s(_vm.supplyBoxPriceInterval))])])]),[_c('div',{staticClass:"__flex_"},_vm._l((_vm.getGroupLevelNum),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"destiny-box",style:({
            background: ("" + (item.level >= 5 ? ("" + (item.color)) : ("rgb(" + (item.color) + ")"))),
          })},[_c('div',{staticClass:"destiny-box-content"},[_c('div',{staticClass:"destiny-box-bgopc",style:({
                background: ("" + (item.level >= 5
                    ? 'rgba(254, 47, 117, 0.05)'
                    : ("rgba(" + (item.color) + ",0.05)"))),
              })},[_c('div',{staticClass:"destiny-box-content-in",style:({
                  background: ("" + (item.level >= 5 ? "#672932" : ("rgba(" + (item.color) + ",0.3)"))),
                })},[_c('div',{staticClass:"destiny-box-content-wrap"},[_c('div',{staticClass:"destiny-box-left",style:({
                      background: ("" + (item.level >= 5
                          ? "#672932"
                          : ("rgba(" + (item.color) + ",0.3)"))),
                    })}),_c('div',{staticClass:"destiny-box-right",style:({
                      background: ("" + (item.level >= 5
                          ? "#672932"
                          : ("rgba(" + (item.color) + ",0.3)"))),
                    })}),_c('div',{staticClass:"destiny-box-opc"},[_c('div',{staticClass:"destiny-box-nums",style:({
                        fontWeight: '800',
                        color: ("" + (item.level >= 5 ? '#FF1F6A' : ("rgb(" + (item.color) + ")"))),
                      })},[_vm._v(" "+_vm._s(item.len)+"款 ")]),_c('div',{staticClass:"destiny-box-title",style:({
                        color: ("" + (item.level >= 5 ? '#FF1F6A' : ("rgb(" + (item.color) + ")"))),
                      })},[_vm._v(" "+_vm._s(item.title)+" ")])])])])])])])])}),0)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }